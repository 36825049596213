import { API } from 'aws-amplify';
import React, { Component, Fragment } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';

import LoaderDiv from '../components/LoaderDiv';

import 'primeflex/primeflex.css';
import './Indexing.css'
import './Posts.css'

export default class Posts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      hasData: false,
      isScaled: false,
      statsFrom: 'netease',
      indexArtists: [],
      sortBy: 'total_rank',
      isReversed: false,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const noChange =
      this.props.month === prevProps.month &&
      this.state.indexArtists === prevState.indexArtists &&
      this.state.isScaled === prevState.isScaled &&
      this.state.statsFrom === prevState.statsFrom;
    if (noChange) {
      return;
    }
    await this.runFetch();
  }

  async runFetch() {
    if (this.state.indexArtists.length < 2) {
      this.setState({ hasData: false });
      return;
    }

    this.setState({ isLoading: true })
    try {
      const unsortedData = await API.post('api', '/index', {
        body: {
          month: this.props.month,
          artists: this.state.indexArtists,
          isScaled: this.state.isScaled,
          statsFrom: this.state.statsFrom,
        }
      });
      const data = this.runSort(unsortedData, this.state.sortBy, this.state.isReversed);
      this.setState({ hasData: true, data });
    } catch(e) {
      alert(e);
    }
    this.setState({ isLoading: false });
  }

  handleChange = event => {
    const target = event.target.id;
    const value = target === 'indexArtists' ?
      [].filter.call(event.target.options, o => o.selected).map(o => o.value) :
      event.target.value;
    this.setState({[target]: value});
  }

  runSort(unsortedData, sortBy, isReversed) {
    const sign = isReversed ? -1 : 1;
    return [].concat(unsortedData)
      .sort((a, b) => a[sortBy] > b[sortBy] ? sign : -sign);
  }

  changeSort = sortBy => {
    const isReversed = sortBy === this.state.sortBy ?
      !this.state.isReversed :
      sortBy !== 'total_rank';
    const data = this.runSort(this.state.data, sortBy, isReversed);
    this.setState({data, sortBy, isReversed});
  }

  onToggle = (target, newState) => {
    this.setState({[target]: newState});
  }

  renderForm() {
    return (
      <Fragment>
        <div className="p-grid p-fluid dashboard">
          <div className="p-col-12 p-lg-6">
            <div className="card">
              <FormGroup controlId="month">
                <FormControl
                  componentClass="select"
                  value={this.props.month}
                  onChange={e => this.props.setMonth(e.target.value)}
                >
                  <option value="ytd">YTD</option>
                  <option value="lifetime">Lifetime</option>
                  {this.props.months.map(m => <option key={m.iso} value={m.iso}>{m.fmt}</option>)}
                </FormControl>
              </FormGroup>
            </div>
          </div>
        <div className="p-col-12 p-lg-6">
          <div className="card">
            <FormGroup controlId="statsFrom">
              <FormControl
                componentClass="select"
                value={this.state.statsFrom}
                onChange={this.handleChange}
              >
                <option value={'netease'}>Netease</option>
                <option value={'instagram'}>Instagram</option>
                <option value={'weibo'}>Weibo</option>
                <option value={'youtube'}>YouTube</option>
              </FormControl>
            </FormGroup>
          </div>
        </div>
          <div className="p-col-12 p-lg-12">
            <div className="card">
              <Toggle
                className="form-toggle"
                onClick={newState => this.onToggle('isScaled', newState)}
                on="Scaled"
                off="Total"
                onstyle="info"
                offstyle="primary"
                active={this.state.isScaled}
              />
            </div>
          </div>
        </div>
        <div className="card">
          <FormGroup controlId="indexArtists">
            <FormControl
              componentClass="select"
              value={this.state.indexArtists}
              onChange={this.handleChange}
              multiple
              size={Math.min(this.props.artists.length, 20)}
            >
              {this.props.artists.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
            </FormControl>
          </FormGroup>
        </div>
      </Fragment>
    );
  }

  renderIndexTable() {
    const { data } = this.state;
    const rankCut = Math.min(3, Math.floor(data.length / 2));

    let artistNames = {};
    this.props.artists.forEach(artist => {
      artistNames[artist.id] = artist.name;
    });

    const hasShares = data.length > 0 && data[0].hasOwnProperty('shares');
    const statsCols = hasShares ?
      ['gross_fans', 'fans', 'likes', 'shares', 'comments'] :
      ['gross_fans', 'fans', 'likes', 'comments'];

    return (
      <table id="sort-table" className="basic-table alternating-table" width="100%">
        <thead>
          <tr>
            <th onClick={() => this.changeSort('total_rank')} className="wide-cell">Artist</th>
            <th onClick={() => this.changeSort('gross_fans')} >Fans</th>
            <th onClick={() => this.changeSort('fans')} className="medium-cell">Fan Index</th>
            <th onClick={() => this.changeSort('likes')}>Likes</th>
            {hasShares && <th onClick={() => this.changeSort('shares')}>Shares</th>}
            <th onClick={() => this.changeSort('comments')} className="medium-cell">Comments</th>
          </tr>
        </thead>
        <tbody>
          {data.map(el => {
            let artistId = el['artist'];
            let artistName = artistNames[artistId];
            return (
              <tr key={artistId}>
                <td>{artistName}</td>
                {statsCols.map(col => {
                  var cellBody;
                  if (col === 'gross_fans') {
                    cellBody = Number(el[col]).toLocaleString();
                  } else if (el[col + '_rank'] <= rankCut) {
                    cellBody = <strong>{el[col].toFixed(2)}</strong>;
                  } else {
                    cellBody = el[col].toFixed(2);
                  }
                  return <td key={col}>{cellBody}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderInfo() {
    return (
      <div className="info">
        <h2>Artist Comparison</h2>
        <p>Select at least 2 artists from the left.</p>
      </div>
    );
  }

  renderIndexing() {
    return (
      <Fragment>
        <div className="p-grid p-fluid dashboard">
          <div className="p-col-12 p-lg-3">
            <div className="card">
              {this.props.hasMeta && this.renderForm()}
            </div>
          </div>
          <div className="p-col-12 p-lg-9">
            <div className="card">
              {this.state.isLoading ?
                <LoaderDiv /> :
                (this.state.hasData ?
                  this.renderIndexTable() :
                  this.renderInfo())
              }
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <div className="Posts Indexing">
        {this.props.isAuthenticated ? this.renderIndexing() : null}
      </div>
    );
  }
}

