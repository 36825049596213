import React from 'react';

import './NoData.css';

export default ({
  text = 'Sorry, nothing to see here.',
  className = '',
  ...props
}) =>
  <div
    className={`NoData ${className}`}
    {...props}
  >
    <p>{text}</p>
  </div>;
