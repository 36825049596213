import { API } from 'aws-amplify';
import { Chart } from 'primereact/chart';
import React, { Component, Fragment } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';

import LoaderDiv from '../components/LoaderDiv.js';
import NoData from '../components/NoData';

import 'primeflex/primeflex.css';
import './MultiArtist.css';

const colors = [
  '#7fc97f',
  '#beaed4',
  '#fdc086',
  '#ffff99',
  '#386cb0',
  '#f0027f',
  '#bf5b17',
  '#666666',
];

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.substring(1);
}

function makeDataset(artistName, yVals, i) {
  return {
    label: artistName,
    data: yVals,
    spanGaps: true,
    borderColor: colors[i],
    backgroundColor: '#00000000',
    cubicInterpolationMode: 'monotone',
  };
}

export default class MultiArtist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasData: false,
      isLoading: false,
      indexArtists: [],
      isGrowth: false,
      isScaled: false,
      statsFrom: 'netease',
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.runFetch();
  }

  async componentDidUpdate(prevProps, prevState) {
    const noChange = this.props.month === prevProps.month &&
      this.state.indexArtists === prevState.indexArtists &&
      this.state.isGrowth === prevState.isGrowth &&
      this.state.statsFrom === prevState.statsFrom;
    if (noChange) {
      return;
    }
    await this.runFetch();
  }

  async runFetch() {
    if (this.state.indexArtists.length < 2) {
      this.setState({ hasData: false });
      return;
    }

    this.setState({ isLoading: true });
    try {
      const data = await API.post('api', '/multi/metrics', {
        body: {
          artists: this.state.indexArtists,
          month: this.props.month,
          ...this.state,
        }
      });
      this.setState({ hasData: true, data });
    } catch(e) {
      alert(e);
    }
    this.setState({ isLoading: false });
  }

  handleChange = event => {
    const target = event.target.id;
    const value = target === 'indexArtists' ?
      [].filter.call(event.target.options, o => o.selected).map(o => o.value) :
      event.target.value;
    this.setState({[target]: value});
  }

  onToggle = (target, newState) => {
    this.setState({[target]: newState});
  }

  renderForm() {
    return (
      <Fragment>
        <div className="p-grid p-fluid dashboard">
          <div className="p-col-12 p-lg-6">
            <div className="card">
              <FormGroup controlId="month">
                <FormControl
                  componentClass="select"
                  value={this.props.month}
                  onChange={e => this.props.setMonth(e.target.value)}
                >
                  <option value="ytd">YTD</option>
                  <option value="lifetime">Lifetime</option>
                  {this.props.months.map(m => <option key={m.iso} value={m.iso}>{m.fmt}</option>)}
                </FormControl>
              </FormGroup>
            </div>
          </div>
          <div className="p-col-12 p-lg-6">
            <div className="card">
              <FormGroup controlId="statsFrom">
                <FormControl
                  componentClass="select"
                  value={this.state.statsFrom}
                  onChange={this.handleChange}
                >
                  <option value={'all'}>All</option>
                  <option value={'china'}>China</option>
                  <option value={'western'}>Western</option>
                  <option value={'netease'}>Netease</option>
                  <option value={'instagram'}>Instagram</option>
                  <option value={'weibo'}>Weibo</option>
                  <option value={'youtube'}>YouTube</option>
                </FormControl>
              </FormGroup>
            </div>
          </div>
          <div className="p-col-12 p-lg-12">
            <div className="card">
              <Toggle
                className="form-toggle"
                onClick={newState => this.onToggle('isGrowth', newState)}
                on="Growth"
                off="Cumulative"
                onstyle="info"
                offstyle="primary"
                active={this.state.isGrowth}
              />
            </div>
          </div>
        </div>
        <div className="card">
          <FormGroup controlId="indexArtists">
            <FormControl
              componentClass="select"
              value={this.state.indexArtists}
              onChange={this.handleChange}
              multiple
              size={Math.min(this.props.artists.length, 20)}
            >
              {this.props.artists.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
            </FormControl>
          </FormGroup>
        </div>
      </Fragment>
    );
  }

  toChart(stat) {
    let artistNames = {};
    this.props.artists.forEach(artist => {
      artistNames[artist.id] = artist.name;
    });
    
    let labels = [];
    let datasets = [];
    let whichArtist = 0;
    Object.entries(this.state.data).forEach(([artist, data]) => {
      let vals = [];
      const artistName = artistNames[artist];
      data.forEach(datum => {
        if (whichArtist === 0) {
          labels.push(datum['fmt_collected_at']);
        }
        vals.push(datum[stat]);
      });

      datasets.push(makeDataset(artistName, vals, whichArtist));
      whichArtist++;
    });
    
    return <Chart
      type="line"
      data={{labels: labels, datasets: datasets}}
      options={{
        title: {
          display: true,
          text: capitalize(stat),
        },
        responsive: true,
        aspectRatio: 1.5,
        scales: {
          yAxes: [{
            stacked: true,
          }]
        },
        legend: {
          display: stat === 'fans',
        },
      }}
    />;
  }

  renderCharts() {
    const hasAnything = Object.entries(this.state.data).length !== 0;
    if (!hasAnything) {
      return <NoData />;
    }
    const hasPosts = this.state.statsFrom !== 'weibo';
    const hasShares = this.state.statsFrom === 'netease';

    return (
      <div className="Dashboard p-grid p-fluid">
        <div className="p-col-12 p-lg-12">
          <div className="card">
            {this.toChart('fans')}
          </div>
        </div>
        {hasPosts &&
          <Fragment>
            {!hasShares && <div className="p-col-12 p-lg-2"/>}
            <div className="p-col-12 p-lg-4">
              <div className="card">
                {this.toChart('likes')}
              </div>
            </div>
            {hasShares &&
              <div className="p-col-12 p-lg-4">
                <div className="card">
                  {this.toChart('shares')}
                </div>
              </div>
            }
            <div className="p-col-12 p-lg-4">
              <div className="card">
                {this.toChart('comments')}
              </div>
            </div>
            {!hasShares && <div className="p-col-12 p-lg-2"/>}
          </Fragment>
        }
      </div>
    );
  }

  renderInfo() {
    return (
      <div className="info">
        <h2>Multi-Artist Aggregates</h2>
        <p>Select at least 2 artists from the left.</p>
      </div>
    );
  }

  renderDashboard() {
    return (
      <Fragment>
        <div className="p-grid p-fluid dashboard">
          <div className="p-col-12 p-lg-3">
            <div className="card">
              {this.props.hasMeta && this.renderForm()}
            </div>
          </div>
          <div className="p-col-12 p-lg-9">
            <div className="card">
              {this.state.isLoading ?
                <LoaderDiv /> :
                (this.state.hasData ?
                  this.renderCharts() :
                  this.renderInfo())}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <div className="MultiArtist">
        {this.props.isAuthenticated ? this.renderDashboard() : this.renderLander()}
      </div>
    );
  }
}
