export default {
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_5KH5jLXMl',
    APP_CLIENT_ID: '7s5bpdfi78tistajmg3rq4dbgt',
    IDENTITY_POOL_ID: 'us-west-2:71ef22f1-3059-49c8-aae1-4ef01c30376e',
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://91dh8pawib.execute-api.us-west-2.amazonaws.com/dev"
  }
};
