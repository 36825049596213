import { API } from 'aws-amplify';
import React, { Component } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';

import LoaderDiv from '../components/LoaderDiv';

import 'primeflex/primeflex.css';
import './Milestones.css'
import './Posts.css'

const weekdayOrder = {
  'Monday': 0,
  'Tuesday': 1,
  'Wednesday': 2,
  'Thursday': 3,
  'Friday': 4,
  'Saturday': 5,
  'Sunday': 6,
}

export default class Milestones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      hasData: false,
      isScaled: false,
      statsFrom: 'netease',
      sortBy: 'date',
      isReversed: true,
    };
  }

  async componentDidMount() {
    this.runFetch();
  }

  async componentDidUpdate(prevProps, prevState) {
    const noChange = this.props.artist === prevProps.artist &&
      this.state.isScaled === prevState.isScaled &&
      this.state.statsFrom === prevState.statsFrom;
    if (noChange) {
      return;
    }
    await this.runFetch();
  }

  async runFetch() {
    this.setState({ isLoading: true });
    try {
      const { isScaled, statsFrom } = this.state;
      const url = `/artist/${this.props.artist}/milestones?isScaled=${isScaled}&statsFrom=${statsFrom}`;
      const rawData = await API.get('api', url);
      const unsortedData = this.addSortKey(rawData);
      const data = this.runSort(unsortedData, this.state.sortBy, this.state.isReversed);
      this.setState({ hasData: true, data });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  addSortKey(data) {
    return data.map(el => {
      return {
        ...el,
        dowIndex: weekdayOrder[el['weekday']],
        releaseKey: `${el['info_release']} ${el['days_after_release']}`,
        mentionKey: `${el['info_mention']} ${el['days_after_mention']}`,
        showKey: `${el['info_show']} ${el['days_after_show']}`,
      }
    });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  onToggle = (target, newState) => {
    this.setState({
      [target]: newState,
    });
  }

  renderForm() {
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-3" />
        <div className="p-col-12 p-lg-2">
          <div className="card">
            <FormGroup controlId="artist">
              <FormControl
                componentClass="select"
                value={this.props.artist}
                onChange={e => this.props.setArtist(e.target.value)}
              >
                {this.props.artists.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-2">
          <div className="card">
            <FormGroup controlId="statsFrom">
              <FormControl
                componentClass="select"
                value={this.state.statsFrom}
                onChange={this.handleChange}
              >
                <option value={'netease'}>Netease</option>
                <option value={'instagram'}>Instagram</option>
                <option value={'weibo'}>Weibo</option>
                <option value={'youtube'}>YouTube</option>
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-2">
          <div className="card">
            <Toggle
              className="form-toggle"
              onClick={newState => this.onToggle('isScaled', newState)}
              on="Scaled"
              off="Total"
              onstyle="info"
              offstyle="primary"
              active={this.state.isScaled}
            />
          </div>
        </div>
        <div className="p-col-12 p-lg-3" />
      </div>
    );
  }

  runSort(unsortedData, sortBy, isReversed) {
    const sign = isReversed ? -1 : 1;
    return [].concat(unsortedData)
      .sort((a, b) => a[sortBy] > b[sortBy] ? sign : -sign);
  }

  changeSort = sortBy => {
    const isReversed = sortBy === this.state.sortBy ?
      !this.state.isReversed :
      sortBy !== 'releaseKey' && sortBy !== 'mentionKey' && sortBy !== 'showKey';
    const data = this.runSort(this.state.data, sortBy, isReversed);
    this.setState({data, sortBy, isReversed});
  }

  renderMilestones() {
    const hasViews = this.state.statsFrom === 'youtube';
    return (
      <table id="milestones-table" className="basic-table">
        <thead>
          <tr>
            <th onClick={() => this.changeSort('dowIndex')}>Day of Week</th>
            <th onClick={() => this.changeSort('date')}>Date</th>
            <th onClick={() => this.changeSort('fans')}>Fan Growth</th>
            {hasViews && <th onClick={() => this.changeSort('views')}>View Growth</th>}
            <th onClick={() => this.changeSort('releaseKey')}>Song Release</th>
            <th onClick={() => this.changeSort('mentionKey')}>KOL Mention</th>
            <th onClick={() => this.changeSort('showKey')}>Show</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map((el, i) => {
            return (
              <tr key={el['date']}>
                <td>{el['weekday']}</td>
                <td>{el['date']}</td>
                <td className={el['fans_color']}>{el['fans']}</td>
                {hasViews && <td className={el['views_color']}>{el['views']}</td>}
                <td className={`release-${el['days_after_release']}`}>
                  {el['info_release']}
                </td>
                <td className={`mention-${el['days_after_mention']}`}>
                  {el['info_mention']}
                </td>
                <td className={`show-${el['days_after_show']}`}>
                  {el['info_show']}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <div className="Posts Milestones">
        {this.props.hasMeta && this.renderForm()}
        {this.state.isLoading ? <LoaderDiv /> : this.renderMilestones()}
      </div>
    );
  }
}

