import { Chart } from 'primereact/chart';
import React, { Component, Fragment } from 'react';

import NoData from '../components/NoData';

import 'primeflex/primeflex.css';
import './Dashboard.css';

const statColors = {
  fans: '#d62728',
  likes: '#1f77b4',
  shares: '#2ca02c',
  comments: '#ff7f0e',

  views: '#2ca02c',
  dislikes: '#a027b4',

  netease: '#1f77b4',
  instagram: '#2ca02c',
  weibo: '#ff7f0e',
  youtube: '#a027b4',
};

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.substring(1);
}

function makeDataset(stat, yVals, overrides) {
  var color = statColors[stat];
  var label = capitalize(stat);
  return Object.assign({
    label: label,
    data: yVals,
    spanGaps: true,
    borderColor: color,
    backgroundColor: '#00000000',
    cubicInterpolationMode: 'monotone'
  }, overrides || {});
}

export default class Dashboard extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.data !== nextProps.data;
  }

  toChart(stat) {
    const isScaled = this.props.isScaled;
    const withMonthOverMonth =
      this.props.withMonthOverMonth &&
      this.props.data &&
      this.dataHas(stat + '_lm');

    var labels = [];
    var yVals = [];
    var yValsLastMonth = [];
    this.props.data.forEach(datum => {
      labels.push(datum['fmt_collected_at']);
      yVals.push(datum[stat]);
      if (withMonthOverMonth) {
        yValsLastMonth.push(datum[stat + '_lm']);
      }
    });

    let datasets = [];
    if (withMonthOverMonth) {
      datasets.push(makeDataset(stat, yVals));
      datasets.push(makeDataset(stat, yValsLastMonth, {
        label: this.props.otherLabel || 'Last Month',
        borderDash: [10, 5]
      }));
    } else {
      datasets.push(makeDataset(stat, yVals));
    }
    return <Chart
      type="line"
      data={{labels: labels, datasets: datasets}}
      options={{
        responsive: true,
        aspectRatio: 1.5,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(label, index, labels) {
                if (!isScaled) {
                  return label;
                }
                let minLabel = Math.min(...labels.map(Math.abs).filter(x => x !== 0))
                let digs = Math.max(0, -Math.floor(2 + Math.log10(minLabel)));
                return (100*label).toFixed(digs) + '%' ;
              },
            }
          }]
        }
      }}
    />;
  }

  multiChart() {
    var labels = [];
    var neteaseVals = [];
    var instagramVals = [];
    var weiboVals = [];
    var youtubeVals = [];
    this.props.data.forEach(datum => {
      labels.push(datum['fmt_collected_at']);
      if (datum.hasOwnProperty('netease')) {
        let val = datum['netease'];
        neteaseVals.push(val < 0 ? null : val);
      }
      if (datum.hasOwnProperty('instagram')) {
        let val = datum['instagram'];
        instagramVals.push(val < 0 ? null : val);
      }
      if (datum.hasOwnProperty('weibo')) {
        let val = datum['weibo'];
        weiboVals.push(val < 0 ? null : val);
      }
      if (datum.hasOwnProperty('youtube')) {
        let val = datum['youtube'];
        youtubeVals.push(val < 0 ? null : val);
      }
    });

    let datasets = [];
    if (neteaseVals.length > 0) {
      datasets.push(makeDataset('netease', neteaseVals));
    }
    if (instagramVals.length > 0) {
      datasets.push(makeDataset('instagram', instagramVals));
    }
    if (weiboVals.length > 0) {
      datasets.push(makeDataset('weibo', weiboVals));
    }
    if (youtubeVals.length > 0) {
      datasets.push(makeDataset('youtube', youtubeVals));
    }
    return <Chart
      type="line"
      data={{labels: labels, datasets: datasets}}
      options={{
        responsive: true,
        aspectRatio: 1.5,
        scales: {
          yAxes: [{
            stacked: true,
          }]
        }
      }}
    />;
  }

  dataHas(prop) {
    return this.props.data &&
      this.props.data.length > 0 &&
      this.props.data[0].hasOwnProperty(prop);
  }

  render() {
    const isAll = this.dataHas('netease') || this.dataHas('instagram');
    if (isAll) {
      return (
        <div className="Dashboard p-grid p-fluid">
          <div className="p-col-12 p-lg-2" />
          <div className="p-col-12 p-lg-8">
            <div className="card">
              {this.multiChart('fans')}
            </div>
          </div>
          <div className="p-col-12 p-lg-2" />
        </div>
      );
    }

    const hasAnything = this.dataHas('fans');
    if (!hasAnything) {
      return <NoData />;
    }

    const hasPosts = this.dataHas('likes') && (!this.props.withMonthOverMonth || this.dataHas('likes_lm'));
    const hasShares = this.dataHas('shares') && (!this.props.withMonthOverMonth || this.dataHas('shares_lm'));
    const hasViews = this.dataHas('views') && (!this.props.withMonthOverMonth || this.dataHas('views_lm'));

    let postCharts = null;
    if (hasPosts) {
      let charts = [];
      if (hasViews) {
        charts.push(this.toChart('views'));
      }
      charts.push(this.toChart('likes'));
      if (hasViews) {
        charts.push(this.toChart('dislikes'));
      }
      if (hasShares) {
        charts.push(this.toChart('shares'));
      }
      charts.push(this.toChart('comments'));

      let chartEls = charts.map(chart => (
        <div className="p-col-12 p-lg-4">
          <div className="card">
            {chart}
          </div>
        </div>
      ));

      if (chartEls.length === 2) {
        postCharts = (
          <Fragment>
            <div className="p-col-12 p-lg-2"/>
            {chartEls[0]}
            {chartEls[1]}
            <div className="p-col-12 p-lg-2"/>
          </Fragment>
        );
      } else if (chartEls.length === 3) {
        postCharts = (
          <Fragment>
            {chartEls[0]}
            {chartEls[1]}
            {chartEls[2]}
          </Fragment>
        );
      } else if (chartEls.length === 4) {
        postCharts = (
          <Fragment>
            <div className="p-col-12 p-lg-2"/>
            {chartEls[0]}
            {chartEls[1]}
            <div className="p-col-12 p-lg-2"/>
            <div className="p-col-12 p-lg-2"/>
            {chartEls[2]}
            {chartEls[3]}
            <div className="p-col-12 p-lg-2"/>
          </Fragment>
        );
      }
    }

    let grossFans = null;
    for (let el of this.props.data) {
      grossFans = el['gross_fans'] || grossFans;
    }

    return (
      <div className="Dashboard p-grid p-fluid">
        {!this.props.skipFanTotal && grossFans !== null &&
          <div className="p-col-12 p-lg-12">
            <div className="card">
              <h3 className="fan-card">
                {Number(grossFans).toLocaleString()} Fans
              </h3>
            </div>
          </div>
        }
        <div className="p-col-12 p-lg-2" />
        <div className="p-col-12 p-lg-8">
          <div className="card">
            {this.toChart('fans')}
          </div>
        </div>
        <div className="p-col-12 p-lg-2" />
        {postCharts}
      </div>
    );
  }
}
