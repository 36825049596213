import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AppliedRoute from './components/AppliedRoute';
import Comparison from './containers/Comparison';
import Home from './containers/Home';
import Indexing from './containers/Indexing';
import Login from './containers/Login';
import NotFound from './containers/NotFound';
import Milestones from './containers/Milestones';
import MultiArtist from './containers/MultiArtist';
import Podium from './containers/Podium';
import Posts from './containers/Posts';
import ShowEditor from './containers/ShowEditor';

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute path="/posts" exact component={Posts} props={childProps} />
    <AppliedRoute path="/podium" exact component={Podium} props={childProps} />
    <AppliedRoute path="/milestones" exact component={Milestones} props={childProps} />
    <AppliedRoute path="/indexing" exact component={Indexing} props={childProps} />
    <AppliedRoute path="/comparison" exact component={Comparison} props={childProps} />
    <AppliedRoute path="/multi-artist" exact component={MultiArtist} props={childProps} />
    <AppliedRoute path="/show-editor" exact component={ShowEditor} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
