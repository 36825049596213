import { Auth } from 'aws-amplify';
import React, { Component } from 'react';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';

import LoaderButton from '../components/LoaderButton';

import './Login.css';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: '',
      password: '',
      confirmPassword: '',
      needsConfirmation: false,
    };
  }

  validateForm() {
    if (this.state.needsConfirmation) {
      return this.state.password.length > 0 &&
        this.state.password === this.state.confirmPassword;
    }
    return this.state.email.length > 0 &&
      this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    try {
      const user = this.state.needsConfirmation ?
        await Auth.completeNewPassword(this.state.user, this.state.password) :
        await Auth.signIn(this.state.email, this.state.password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.setState({
          isLoading: false,
          password: '',
          needsConfirmation: true,
          user: user,
        })
      } else {
        this.props.userHasAuthenticated(true);
        this.props.history.push('/');
      }
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          {this.state.needsConfirmation &&
            <FormGroup bsSize="large" controlId="confirmPassword">
              <ControlLabel>Confirm Password</ControlLabel>
              <FormControl
                type="password"
                onChange={this.handleChange}
                value={this.state.confirmPassword}
              />
            </FormGroup>
          }
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text={this.state.needsConfirmation ? 'Change' : 'Login'}
            loadingText={this.state.needsConfirmation ? 'Changing…' : 'Logging in…'}
          />
        </form>
      </div>
    );
  }
}
