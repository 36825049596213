import { API } from 'aws-amplify';
import React, { Component } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';

import LoaderDiv from '../components/LoaderDiv';
import NoData from '../components/NoData';

import 'primeflex/primeflex.css';
import './Posts.css'

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.substring(1);
}

export default class Posts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      hasData: false,
      statsFrom: 'netease',
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.runFetch();
  }

  async componentDidUpdate(prevProps, prevState) {
    const noChange = this.props.artist === prevProps.artist &&
      this.props.month === prevProps.month &&
      this.state.statsFrom === prevState.statsFrom;
    if (noChange) {
      return;
    }
    await this.runFetch();
  }

  async runFetch() {
    this.setState({ isLoading: true });
    try {
      const data = await API.post('api', '/posts', {
        body: {
          artist: this.props.artist,
          month: this.props.month,
          statsFrom: this.state.statsFrom,
        }
      });
      this.setState({ hasData: true, data });
    } catch(e) {
      alert(e);
    }
    this.setState({ isLoading: false });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  renderForm() {
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-3" />
        <div className="p-col-12 p-lg-2">
          <div className="card">
            <FormGroup controlId="artist">
              <FormControl
                componentClass="select"
                value={this.props.artist}
                onChange={e => this.props.setArtist(e.target.value)}
              >
                {this.props.artists.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-2">
          <div className="card">
            <FormGroup controlId="month">
              <FormControl
                componentClass="select"
                value={this.props.month}
                onChange={e => this.props.setMonth(e.target.value)}
              >
                {this.props.months.map(m => <option key={m.iso} value={m.iso}>{m.fmt}</option>)}
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-2">
          <div className="card">
            <FormGroup controlId="statsFrom">
              <FormControl
                componentClass="select"
                value={this.state.statsFrom}
                onChange={this.handleChange}
              >
                <option value={'netease'}>Netease</option>
                <option value={'instagram'}>Instagram</option>
                <option value={'weibo'}>Weibo</option>
                <option value={'youtube'}>YouTube</option>
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-3" />
      </div>
    );
  }

  totalsTable() {
    const data = this.state.data['totals'];
    return (
      <table className="basic-table">
        <thead>
          <tr>
            <th></th><th>Total</th><th>Per-Post</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Posts</strong></td>
            <td>{data['count']}</td>
            <td>-</td>
          </tr>
          {Object.keys(data['stats']).map(stat => {
            var val = data['stats'][stat];
            return <tr key={stat}>
              <td><strong>{capitalize(stat)}</strong></td>
              <td>{Number(val['total']).toLocaleString()}</td>
              <td>{Number(val['per']).toLocaleString()}</td>
            </tr>;
          })}
        </tbody>
      </table>
    );
  }

  rankedTable(prefix) {
    const hasShares = 
      this.state.data[prefix].length > 0 &&
      this.state.data[prefix][0].hasOwnProperty('shares');
    const statsCols = hasShares ?
      ['likes', 'shares', 'comments'] :
      ['likes', 'comments'];
    return (
      <table className="basic-table">
        <thead>
          <tr>
            <th>Post Time</th>
            <th>Likes</th>
            {hasShares && <th>Shares</th>}
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data[prefix].map(el => {
            var link = el['permalink'];
            var when = el['fmt_post_time'];
            return <tr key={link}>
              <td><a href={link}>{when}</a></td>
              {statsCols.map(col => {
                var amount = Number(el[col]).toLocaleString();
                var is_bold = el[prefix + '_' + col];
                return <td key={col}>
                  {is_bold ? <strong>{amount}</strong> : amount}
                </td>;
              })}
            </tr>
          })}
        </tbody>
      </table>
    );
  }

  renderPostList() {
    if (!this.state.data.hasOwnProperty('totals')) {
      return <NoData />;
    }
    return (
      <div className="posts">
        <h2>Post Performance</h2>
        <h3>Overall</h3>
        {this.totalsTable()}
        <h3>Best Posts</h3>
        {this.rankedTable('best')}
        <h3>Weak Posts</h3>
        {this.rankedTable('weak')}
      </div>
    );
  }

  renderPosts() {
    return (
      <div>
        {this.props.hasMeta && this.renderForm()}
        {this.state.isLoading ? <LoaderDiv /> : this.renderPostList()}
      </div>
    );
  }

  render() {
    return (
      <div className="Posts">
        {this.props.isAuthenticated ? this.renderPosts() : this.renderLander()}
      </div>
    );
  }
}

