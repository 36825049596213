import { API } from 'aws-amplify';
import React, { Component } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';

import NoData from '../components/NoData';
import LoaderDiv from '../components/LoaderDiv';

import 'primeflex/primeflex.css';
import './Posts.css'
import './Podium.css'

const postTagOptions = [
  'Video',
  'Skyline',
  'Local Food',
  'Fan Shot',
  'Street Lifestyle',
  'DJ Friend Shot',
  'Performance Shot',
  'China Team Shot',
  'Facial Performance Shot',
  'Market Relatable Content',
  'Candid',
  'Family',
  'Throwback',
  'Other',
];

const videoTagOptions = [
  'Music Video',
  'Lyric Video',
  'Cover Art Video',
  'Tour Teaser',
  'Song Teaser',
  'Performance',
  'Performance Recap',
  'Interview',
  'Art Piece',
  'Other',
];

function untitleify(s) {
  return s.split(' ').map(s => s.toLowerCase()).join('_');
}

function makeTagMap(names) {
  let obj = {};
  names.forEach(name => {
    obj[untitleify(name)] = name;
  });
  return obj;
}
const tagsNamesById = makeTagMap(postTagOptions.concat(videoTagOptions));

function tagName(tag, sub) {
  if (tag === null) {
    return <p className="no-tag">Uncategorized</p>;
  }
  return tagsNamesById[tag];
}

export default class Podium extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      hasData: false,
      isSummary: true,
      isScaled: false,
      statsFrom: 'netease',
      isEdit: false,
      tags: {},
      sortBy: false,
      isReversed: true,
    };
  }

  async componentDidMount() {
    this.runFetch();
  }

  async componentDidUpdate(prevProps, prevState) {
    const noChange =
      this.props.artist === prevProps.artist &&
      this.state.isSummary === prevState.isSummary &&
      this.state.isScaled === prevState.isScaled &&
      this.state.statsFrom === prevState.statsFrom;
    if (noChange) {
      return;
    }
    await this.runFetch();
  }

  async runFetch() {
    this.setState({ isLoading: true, isEdit: false });
    try {
      const { isSummary, isScaled, statsFrom } = this.state;
      const url = `/artist/${this.props.artist}/posts?isSummary=${isSummary}&isScaled=${isScaled}&statsFrom=${statsFrom}`;
      const unsortedData = await API.get('api', url);
      const sortBy = this.state.sortBy || (this.state.isSummary ? 'likes' : 'posted_at');
      const data = this.runSort(unsortedData, sortBy, this.state.isReversed);
      this.setState({ hasData: true, data });
    } catch (e) {
      alert(e);
    }
    this.setState({ isLoading: false });
  }

  async saveTag(tag) {
    try {
      await API.post('api', '/save_tags', {
        body: {
          tags: tag,
        }
      });
    } catch (e) {
      alert(e);
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  tagChange = async (event) => {
    const tag = {
      [event.target.id]: event.target.value
    };
    await this.saveTag(tag);
    this.setState({
      tags: {
        ...this.state.tags,
        ...tag,
      }
    });
  }

  onToggle = (target, newState) => {
    this.setState({
      [target]: newState,
      hasData: target !== 'isSummary',
    });
  }

  renderForm() {
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-2" />
        <div className="p-col-12 p-lg-3">
          <div className="card">
            <FormGroup controlId="artist">
              <FormControl
                componentClass="select"
                value={this.props.artist}
                onChange={e => this.props.setArtist(e.target.value)}
              >
                {this.props.artists.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-2">
          <div className="card">
            <FormGroup controlId="statsFrom">
              <FormControl
                componentClass="select"
                value={this.state.statsFrom}
                onChange={this.handleChange}
              >
                <option value={'netease'}>Netease</option>
                <option value={'instagram'}>Instagram</option>
                <option value={'weibo'}>Weibo</option>
                <option value={'youtube'}>YouTube</option>
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-4">
          <div className="card">
            <Toggle
              className="form-toggle"
              onClick={newState => this.onToggle('isSummary', newState)}
              on="Summary"
              off="Per-Post"
              onstyle="info"
              offstyle="primary"
              active={this.state.isSummary}
            />
            <Toggle
              className="form-toggle"
              onClick={newState => this.onToggle('isScaled', newState)}
              on="Scaled"
              off="Total"
              onstyle="info"
              offstyle="primary"
              active={this.state.isScaled}
            />
            <Toggle
              className="form-toggle"
              onClick={newState => this.onToggle('isEdit', newState)}
              on="Edit"
              off="View"
              onstyle="info"
              offstyle="primary"
              active={this.state.isEdit}
              disabled={this.state.isSummary}
            />
          </div>
        </div>
        <div className="p-col-12 p-lg-1" />
      </div>
    );
  }

  tagOptions(postId, tag) {
    if (!this.state.isEdit) {
      return tagName(tag);
    }

    const tagOptions = this.state.statsFrom === 'youtube' ?
      videoTagOptions : postTagOptions;

    return (
      <FormGroup controlId={postId}>
        <FormControl
          componentClass="select"
          value={tag || -1}
          onChange={this.tagChange}
        >
          <option hidden disabled value={-1}>Uncategorized</option>
          {tagOptions.map(tagOpt => {
            let key = untitleify(tagOpt);
            return <option key={key} value={key}>{tagOpt}</option>;
          })}
        </FormControl>
      </FormGroup>
    );
  }

  runSort(unsortedData, sortBy, isReversed) {
    const sign = isReversed ? -1 : 1;
    return [].concat(unsortedData)
      .sort((a, b) => a[sortBy] > b[sortBy] ? sign : -sign);
  }

  changeSort = sortBy => {
    const isReversed = sortBy === this.state.sortBy ? !this.state.isReversed : true;
    const data = this.runSort(this.state.data, sortBy, isReversed);
    this.setState({data, sortBy, isReversed});
  }

  dataHas(prop) {
    return this.state.data &&
      this.state.data.length > 0 &&
      this.state.data[0].hasOwnProperty(prop);
  }

  renderSummary() {
    const hasShares = this.dataHas('shares');
    const hasViews = this.dataHas('views');

    let statCols = hasShares ? ['likes', 'shares', 'comments'] : ['likes', 'comments'];
    if (hasViews) {
      statCols = ['views', 'likes', 'dislikes', 'comments'];
    }

    return (
      <table id="posts-table" className="basic-table">
        <thead>
          <tr>
            <th onClick={() => this.changeSort('tag')}>Tag</th>
            <th onClick={() => this.changeSort('count')}>Posts</th>
            {hasViews && <th onClick={() => this.changeSort('views')}>Views</th>}
            <th onClick={() => this.changeSort('likes')}>Likes</th>
            {hasViews && <th onClick={() => this.changeSort('dislikes')}>Dislikes</th>}
            {hasShares && <th onClick={() => this.changeSort('shares')}>Shares</th>}
            <th onClick={() => this.changeSort('comments')}>Comments</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map((el, i) => {
            return (
              <tr key={el['tag']}>
                <td>{tagName(el['tag'])}</td>
                <td>{el['count']}</td>
                {statCols.map(col => {
                  var amount = el[col];
                  var color = el[col + '_color'];
                  var cellBody;
                  if (this.state.isScaled) {
                    cellBody = amount.toFixed(1)
                  } else {
                    cellBody = Number(amount).toLocaleString();
                  }
                  return <td key={col} className={color}>{cellBody}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderPerPost() {
    const hasShares = this.dataHas('shares');
    const hasViews = this.dataHas('views');

    let statCols = hasShares ? ['likes', 'shares', 'comments'] : ['likes', 'comments'];
    if (hasViews) {
      statCols = ['views', 'likes', 'dislikes', 'comments'];
    }

    return (
      <table id="posts-table" className="basic-table">
        <thead>
          <tr>
            {hasViews ?
              <th onClick={() => this.changeSort('posted_at')}>Title</th> :
              <th onClick={() => this.changeSort('posted_at')}>Post Time</th>
            }
            {hasViews && <th onClick={() => this.changeSort('views')}>Views</th>}
            <th onClick={() => this.changeSort('likes')}>Likes</th>
            {hasViews && <th onClick={() => this.changeSort('dislikes')}>Dislikes</th>}
            {hasShares && <th onClick={() => this.changeSort('shares')}>Shares</th>}
            <th onClick={() => this.changeSort('comments')}>Comments</th>
            <th onClick={() => this.changeSort('tag')}>Tag</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data.map((el, i) => {
            let link = el['permalink'];
            let when = el['fmt_post_time'];
            let tag = this.state.tags[el['id']] || el['tag'];
            return (
              <tr key={link}>
                <td>
                  {hasViews ?
                    <a href={link} title={`Posted on ${when}`}>{el['title']}</a> :
                    <a href={link}>{when}</a>
                  }
                </td>
                {statCols.map(col => {
                  var amount = el[col];
                  var cellBody;
                  if (this.state.isScaled) {
                    cellBody = amount.toFixed(1)
                  } else {
                    cellBody = Number(amount).toLocaleString();
                  }
                  var color = el[col + '_color'];
                  return <td key={col} className={color}>{cellBody}</td>;
                })}
                <td className={this.state.isEdit ? 'has-select' : 'pad-select'}>
                  {this.tagOptions(el['id'], tag)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderPodium() {
    if (this.state.hasData && this.state.data.length === 0) {
      return <NoData text={this.state.isSummary ?
       'Want a summary? Edit tags in per-post view.' :
       'Sorry, nothing to see here.'
      }/>;
    }
    return this.state.isSummary ? this.renderSummary() : this.renderPerPost();
  }

  render() {
    return (
      <div className="Posts Podium">
        {this.props.hasMeta && this.renderForm()}
        {this.state.isLoading ? <LoaderDiv/> : this.renderPodium()}
      </div>
    );
  }
}

