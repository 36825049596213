import { API } from 'aws-amplify';
import React, { Component } from 'react';
import { Button, FormControl, FormGroup, Glyphicon } from 'react-bootstrap';

import NoData from '../components/NoData';
import LoaderDiv from '../components/LoaderDiv';

import 'primeflex/primeflex.css';
import './Posts.css'
import './ShowEditor.css'

function arrReplace(arr, i, obj) {
  return [
    ...arr.slice(0, i),
    obj,
    ...arr.slice(i+1),
  ];
}

function arrRemove(arr, i) {
  return [...arr.slice(0, i), ...arr.slice(i+1)];
}

function objReplace(obj, key, val) {
  return { ...obj, [key]: val };
}

export default class ShowEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      hasData: false,
      isEdit: false,
      shows: [],
    };
  }

  async componentDidMount() {
    this.runFetch();
  }

  async componentDidUpdate(prevProps, prevState) {
    const noChange = this.props.artist === prevProps.artist;
    if (noChange) {
      return;
    }
    await this.runFetch();
  }

  async runFetch() {
    this.setState({ isLoading: true, isEdit: false });
    try {
      const url = `/artist/${this.props.artist}/shows`;
      const unsortedData = await API.get('api', url);
      const shows = this.runSort(unsortedData, 'date', true);
      this.setState({ hasData: true, shows });
    } catch (e) {
      alert(e);
    }
    this.setState({ isLoading: false });
  }

  runSort(unsortedData, sortBy, isReversed) {
    const sign = isReversed ? -1 : 1;
    return [].concat(unsortedData)
      .sort((a, b) => a[sortBy] > b[sortBy] ? sign : -sign);
  }

  save = async () => {
    try {
      const shows = this.state.editShows.slice(1);
      const url = `/artist/${this.props.artist}/shows`;
      await API.post('api', url, { body: { shows } });
      this.setState({ isEdit: false, shows });
    } catch (e) {
      alert(e);
    }
  }

  cancelEdit = () => {
    this.setState({ isEdit: false })
  }

  startEdit = async () => {
    this.setState({
      isEdit: true,
      editShows: [
        {date: '', city: '', country: '', venue: ''},
        ...this.state.shows,
      ],
    });
  }

  renderForm() {
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-4" />
        <div className="p-col-12 p-lg-3">
          <div className="card">
            <FormGroup controlId="artist">
              <FormControl
                componentClass="select"
                value={this.props.artist}
                onChange={e => this.props.setArtist(e.target.value)}
              >
                {this.props.artists.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-3">
          <div className="card">
            {this.state.isEdit &&
              <Button size="lg" onClick={this.cancelEdit} className="has-right">
                Cancel
              </Button>
            }
            <Button
              size="lg"
              bsStyle={this.state.isEdit ? 'primary' : 'default'}
              onClick={this.state.isEdit ? this.save : this.startEdit}
              disabled={!this.validate()}
            >
              {this.state.isEdit ? 'Save' : 'Edit'}
            </Button>
          </div>
        </div>
        <div className="p-col-12 p-lg-4" />
      </div>
    );
  }

  renderView() {
    if (this.state.hasData && this.state.shows.length === 0) {
      return <NoData text="No shows. Use Edit to add some." />;
    }
    return (
      <table id="shows-table" className="basic-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Venue</th>
            <th>City</th>
            <th>Country</th>
          </tr>
        </thead>
        <tbody>
          {this.state.shows.map((el, i) => {
            const { date, venue, city, country } = el;
            return (
              <tr key={i}>
                <td>{date}</td>
                <td>{venue}</td> 
                <td>{city}</td>
                <td>{country}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  isValidDate(dateString) {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!dateString.match(regEx)) {
      return false;
    }
    
    var d = new Date(dateString);
    if(Number.isNaN(d.getTime())) {
      return false;
    }
    return d.toISOString().slice(0, 10) === dateString;
  }

  isValidIndex(i) {
    const { date, venue, city, country } = this.state.editShows[i];
    return this.isValidDate(date) &&
      venue && venue.length > 0 &&
      city && city.length > 0 &&
      country && country.length > 0;
  }

  validate = () => {
    if (!this.state.isEdit) {
      return true;
    }
    for (let i = 1; i < this.state.editShows.length; i++) {
      if (!this.isValidIndex(i)) {
        return false;
      }
    }
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleAdd = () => {
    this.setState({
      editShows: [
        {date: '', city: '', country: '', venue: ''},
        ...this.state.editShows,
      ],
    });
  }

  handleEdit = (i, key, event) => {
    this.setState({
      editShows: arrReplace(
        this.state.editShows,
        i,
        objReplace(
          this.state.editShows[i],
          key,
          event.target.value,
        ),
      ),
    });
  }

  handleRemove = (i) => {
    this.setState({
      editShows: arrRemove(this.state.editShows, i)
    });
  }

  renderEdit() {
    return (
      <table id="shows-table" className="basic-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Venue</th>
            <th>City</th>
            <th>Country</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.state.editShows.map((el, i) => {
            const { date, venue, city, country } = el;
            const invalid = i === 0 ? 'warning' : 'error';
            return (
              <tr key={i}>
                <td>
                  <FormGroup validationState={this.isValidDate(date) ? null : invalid}>
                    <FormControl
                      value={date}
                      onChange={e => this.handleEdit(i, 'date', e)}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup validationState={venue && venue.length > 0 ? null : invalid}>
                    <FormControl
                      value={venue}
                      onChange={e => this.handleEdit(i, 'venue', e)}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup validationState={city.length > 0 ? null : invalid}>
                    <FormControl
                      value={city}
                      onChange={e => this.handleEdit(i, 'city', e)}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup validationState={country && country.length > 0 ? null : invalid}>
                    <FormControl
                      value={country}
                      onChange={e => this.handleEdit(i, 'country', e)}
                    />
                  </FormGroup>
                </td>
                <td>
                  {i === 0 ?
                    <Button
                      bsStyle="success"
                      onClick={() => this.handleAdd()}
                      disabled={!this.isValidIndex(i)}
                    >
                      <Glyphicon glyph="ok" />
                    </Button> :
                    <Button
                      bsStyle="danger"
                      onClick={() => this.handleRemove(i)}
                    >
                      <Glyphicon glyph="remove" />
                    </Button>
                  }
                  
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderBody() {
    return this.state.isEdit ? this.renderEdit() : this.renderView();
  }

  render() {
    return (
      <div className="Posts ShowEditor">
        {this.props.hasMeta && this.renderForm()}
        {this.state.isLoading ? <LoaderDiv/> : this.renderBody()}
      </div>
    );
  }
}
