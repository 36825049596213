import React from 'react';
import { Glyphicon } from 'react-bootstrap';

import './LoaderDiv.css';

export default ({
  isLoading,
  className = '',
  ...props
}) =>
  <div
    className={`LoaderDiv ${className}`}
    {...props}
  >
    {<Glyphicon glyph="refresh" className="spinning" />}
  </div>;
