import { Auth, API } from 'aws-amplify';
import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import './App.css';
import Routes from './Routes';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      hasMeta: false,
      artists: [],
      months: [],
      artist: 452870621,
      month: '',
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);

      const artists = await API.get('api', '/artist');
      const months = await API.get('api', '/month');
      this.setState({
        hasMeta: true,
        artists,
        months,
        month: months[0].iso,
      });
    } catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  setArtist = artist => {
    this.setState({ artist });
  }

  setMonth = month => {
    this.setState({ month });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push('/login');
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      hasMeta: this.state.hasMeta,
      artists: this.state.artists,
      months: this.state.months,
      artist: this.state.artist,
      month: this.state.month,
      setArtist: this.setArtist,
      setMonth: this.setMonth,
      userHasAuthenticated: this.userHasAuthenticated,
    };

    if (this.state.isAuthenticating) {
      return null;
    }
    return (
      <div className="App container">
        <Navbar fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">+364</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            {this.state.isAuthenticated &&
              <Nav>
                <LinkContainer to="/posts">
                  <NavItem>Posts</NavItem>
                </LinkContainer>
                <LinkContainer to="/podium">
                  <NavItem>Podium</NavItem>
                </LinkContainer>
                <LinkContainer to="/milestones">
                  <NavItem>Milestones</NavItem>
                </LinkContainer>
                <LinkContainer to="/indexing">
                  <NavItem>Indexing</NavItem>
                </LinkContainer>
                <LinkContainer to="/comparison">
                  <NavItem>Comparison</NavItem>
                </LinkContainer>
                <LinkContainer to="/multi-artist">
                  <NavItem>Multi-Artist</NavItem>
                </LinkContainer>
                <LinkContainer to="/show-editor">
                  <NavItem>Shows</NavItem>
                </LinkContainer>
              </Nav>
            }
            <Nav pullRight>
              {this.state.isAuthenticated
                ? <NavItem onClick={this.handleLogout}>Logout</NavItem>
                : <Fragment>
                    <LinkContainer to="/login">
                      <NavItem>Login</NavItem>
                    </LinkContainer>
                  </Fragment>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default withRouter(App);
