import { API } from 'aws-amplify';
import React, { Component } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';

import LoaderDiv from '../components/LoaderDiv.js';
import Dashboard from './Dashboard.js';

import 'primeflex/primeflex.css';
import './Home.css';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasData: false,
      isLoading: true,
      isScaled: false,
      isGrowth: false,
      statsFrom: 'netease',
      withMonthOverMonth: true,
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.runFetch();
  }

  async componentDidUpdate(prevProps, prevState) {
    const noChange = this.props.artist === prevProps.artist &&
      this.props.month === prevProps.month &&
      this.state.isScaled === prevState.isScaled &&
      this.state.isGrowth === prevState.isGrowth &&
      this.state.statsFrom === prevState.statsFrom &&
      this.state.withMonthOverMonth === prevState.withMonthOverMonth;
    if (noChange) {
      return;
    }
    await this.runFetch();
  }

  async runFetch() {
    this.setState({ isLoading: true });
    try {
      const data = await API.post('api', '/metrics', {
        body: {
          artist: this.props.artist,
          month: this.props.month,
          ...this.state,
        }
      });
      this.setState({ hasData: true, data });
    } catch(e) {
      alert(e);
    }
    this.setState({ isLoading: false });
  }

  handleChange = event => {
    let updates = {
      [event.target.id]: event.target.value
    };
    if (event.target.id === 'statsFrom' && event.target.value === 'all') {
      updates['isScaled'] = false;
    }
    this.setState(updates);
  }

  onToggle = (target, newState) => {
    this.setState({[target]: newState});
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>+364</h1>
        <p>Engage every day</p>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-3" />
        <div className="p-col-12 p-lg-2">
          <div className="card">
            <FormGroup controlId="artist">
              <FormControl
                componentClass="select"
                value={this.props.artist}
                onChange={e => this.props.setArtist(e.target.value)}
              >
                {this.props.artists.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-2">
          <div className="card">
            <FormGroup controlId="month">
              <FormControl
                componentClass="select"
                value={this.props.month}
                onChange={e => this.props.setMonth(e.target.value)}
              >
                {this.props.months.map(m => <option key={m.iso} value={m.iso}>{m.fmt}</option>)}
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-2">
          <div className="card">
            <FormGroup controlId="statsFrom">
              <FormControl
                componentClass="select"
                value={this.state.statsFrom}
                onChange={this.handleChange}
              >
                <option value={'all'}>All</option>
                <option value={'china'}>China</option>
                <option value={'western'}>Western</option>
                <option value={'netease'}>Netease</option>
                <option value={'instagram'}>Instagram</option>
                <option value={'weibo'}>Weibo</option>
                <option value={'youtube'}>YouTube</option>
              </FormControl>
            </FormGroup>
          </div>
        </div>
        <div className="p-col-12 p-lg-3" />
        <div className="p-col-12 p-lg-3" />
        <div className="p-col-12 p-lg-4">
          <div className="card">
            <Toggle
              className="form-toggle"
              onClick={newState => this.onToggle('withMonthOverMonth', newState)}
              on="MoM Enabled"
              off="MoM Disabled"
              onstyle="info"
              offstyle="primary"
              active={this.state.withMonthOverMonth}
            />
            <Toggle
              className="form-toggle"
              onClick={newState => this.onToggle('isScaled', newState)}
              on="Scaled"
              off="Total"
              onstyle="info"
              offstyle="primary"
              active={this.state.isScaled}
              disabled={this.state.statsFrom === 'all'}
            />
            <Toggle
              className="form-toggle"
              onClick={newState => this.onToggle('isGrowth', newState)}
              on="Growth"
              off="Cumulative"
              onstyle="info"
              offstyle="primary"
              active={this.state.isGrowth}
            />
          </div>
        </div>
        <div className="p-col-12 p-lg-5" />
      </div>
    );
  }

  renderDashboard() {
    return (
      <div className="dash">
        {this.props.hasMeta && this.renderForm()}
        {this.state.isLoading ?
          <LoaderDiv /> :
          <Dashboard
            data={this.state.data}
            isScaled={this.state.isScaled}
            withMonthOverMonth={this.state.withMonthOverMonth}
          />
        }
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderDashboard() : this.renderLander()}
      </div>
    );
  }
}
